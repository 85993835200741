import React, { useEffect, useMemo, useState } from "react";
import { useDeleteCategoryMutation, useGetCategoriesQuery } from "../redux/api";
import { TableSkeleton } from "../../components/skeletons/table";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactTable, TableColumn } from "../../components/table";
import { CategoryInterface } from "../redux/types";
import { ActionColumn } from "../../components/actionColumn";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const CategoryList = () => {
  const { data, isLoading } = useGetCategoriesQuery();
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState<CategoryInterface[]>([]);
  const [deleteCategory, { isSuccess }] = useDeleteCategoryMutation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  const columns: TableColumn<CategoryInterface>[] = useMemo(
    () => [
      {
        header: "category_name",
        cell: (row) => row.name[currentLanguage],
      },
      {
        header: "is_visible",
        cell: (row) => (row.is_visible ? t("yes") : t("no")),
      },
      {
        cell: (row) => (
          <ActionColumn onDeleteClick={() => deleteCategory(row.id)} />
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (data) {
      setCategories(data);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("notifications.category_deleted_successfully"));
    }
  }, [isSuccess, t]);

  return (
    <React.Fragment>
      <Box flex={1}>
        <Typography marginBottom={4} fontWeight={700} variant="h5">
          {t("categories")}
        </Typography>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Card>
            <CardContent>
              <ReactTable data={categories} columns={columns} />
            </CardContent>
          </Card>
        )}
      </Box>
    </React.Fragment>
  );
};
