import { useNavigate, useParams } from "react-router-dom";
import { useGetAnswerQuery, useUpdateAnswerMutation } from "../redux/api";
import { FormAnswerProps } from "../redux/types";
import { AnswerForm } from "./parts/form";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const AnswerEdit = () => {
  const { t } = useTranslation();
  const [updateAnswer, { isSuccess, error, isError }] =
    useUpdateAnswerMutation();
  const { id, question_id } = useParams<{ id: string; question_id: string }>();
  const { data } = useGetAnswerQuery({
    id: id!,
    question_id: question_id!,
  });
  const navigate = useNavigate();

  const onSubmit = (data: FormAnswerProps) => {
    updateAnswer({ ...data, id: id!, question_id: question_id! });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("notifications.answer_updated"));
      navigate(`/questions/${question_id}`);
    }
  }, [isSuccess]);

  return (
    <Box flex={1}>
      <AnswerForm
        title="edit_answer"
        data={data}
        onSubmit={onSubmit}
        error={error}
        isError={isError}
      />
    </Box>
  );
};
