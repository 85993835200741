import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { DropdownProfile } from "./dropdownProfile";

type NavbarProps = {
  onOpen: () => void;
};

export const Navbar = (props: NavbarProps) => {
  return (
    <AppBar
      sx={{
        width: "calc(100% - 280px)",
        left: 280,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(6px)",
        boxShadow: "none",
      }}
      position="sticky"
    >
      <Toolbar>
        <Box display={"flex"} sx={{ flexGrow: 1, justifyContent: "flex-end" }}>
          <DropdownProfile />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
