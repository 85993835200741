import { AnswerInterface } from "./../../answers/redux/types";
import {
  PaginationDataInterface,
  SuccessResponseInterface,
} from "./../../../app/types";
import { CategoryInterface } from "./../../categories/redux/types";

export interface QuestionInterface {
  id: number;
  question_category_id: number;
  question_category: CategoryInterface;
  question: {
    [key: string]: string;
  };
  level: QuestionLevelEnum;
  answers?: AnswerInterface[];
}

export enum QuestionLevelEnum {
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
}

export interface GetQuestionsSuccessResponseInterface
  extends PaginationDataInterface<QuestionInterface> {}

export interface GetQuestionSuccessResponseInterface
  extends SuccessResponseInterface {
  data: QuestionInterface;
}

export interface SaveQuestionPropsInterface {
  category_id: number;
  question: {
    [key: string]: string;
  };
  level: QuestionLevelEnum;
}
