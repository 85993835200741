import React, { ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export interface TableColumn<T> {
  header?: string;
  accessor?: keyof T;
  cell?: (row: T) => string | number | JSX.Element;
}

type PaginationProps = {
  page: number;
  perPage: number;
};

interface MyTableProps<T> {
  data: T[];
  columns: TableColumn<T>[];
  paginated?: boolean;
  perPage?: number;
  onPaginate?: ({ page, perPage }: PaginationProps) => void;
  currentPage?: number;
  count?: number;
}

export const ReactTable = <T extends object>(props: MyTableProps<T>) => {
  const { columns, data, paginated, perPage, onPaginate, currentPage, count } =
    props;
  const { t } = useTranslation();

  const handleChangePage = (event: unknown, pageIndex: number) => {
    if (!onPaginate) return;

    onPaginate({
      page: pageIndex,
      perPage: perPage ?? 0,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!onPaginate) return;

    onPaginate({ page: 1, perPage: parseInt(event.target.value, 10) });
  };

  return (
    <React.Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>
                  <TableSortLabel>
                    {column.header && t(column.header)}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column, index) => (
                  <TableCell key={index}>
                    {column.cell
                      ? column.cell(row)
                      : column.accessor
                      ? (row[column.accessor] as unknown as ReactNode)
                      : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {paginated && onPaginate && (
        <TablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={count ?? 0}
          rowsPerPage={perPage ?? 0}
          page={currentPage ?? 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("pagination.rows_per_page")}
        />
      )}
    </React.Fragment>
  );
};
