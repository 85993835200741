import { Box } from "@mui/material";
import { QuestionCreate } from "./create";
import { QuestionEdit } from "./edit";
import { QuestionDetails } from "./show";
import { QuestionList } from "./index";

type Props = {
  children: JSX.Element;
};
export const Question = ({ children }: Props) => {
  return <Box flex={1}>{children}</Box>;
};

Question.Create = QuestionCreate;
Question.Edit = QuestionEdit;
Question.Details = QuestionDetails;
Question.List = QuestionList;
