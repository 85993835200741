import { FormProps } from "app/types";
import {
  AwardInterface,
  SaveAwardPropsInterface,
} from "features/awards/api/types";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { supportedLanguages } from "languages/i18n";
import { useDropzone } from "react-dropzone";
import { useGetSettingsQuery } from "../../../settings/api/repository";
import { SettingEnum } from "../../../settings/api/types";
import _ from "lodash";
import { toast } from "react-toastify";

export const AwardForm = (
  props: FormProps<SaveAwardPropsInterface, AwardInterface>
) => {
  const { t } = useTranslation();
  const [data, setData] = useState<SaveAwardPropsInterface>({
    name: {},
    description: {},
    places: [],
    image: null,
  });
  const settingsQuery = useGetSettingsQuery();
  const [pricePool, setPricePool] = useState<number[]>([]);
  const [errors, setErrors] = useState<{
    name: boolean;
    description: boolean;
    places: boolean;
    image: boolean;
  }>(initialError);

  const handleFile = (files: File[]) => {
    const file = files[0];
    setData((data) => ({ ...data, image: file }));

    document
      .getElementById("image")
      ?.setAttribute("src", URL.createObjectURL(file));
  };

  useEffect(() => {
    if (props.data) {
      const { name, description, places } = props.data;

      setData((data) => ({
        ...data,
        name: name,
        description: description,
        places: places,
      }));

      document.getElementById("image")?.setAttribute("src", props.data.image);
    }
  }, [props.data]);

  useEffect(() => {
    if (settingsQuery.data) {
      const pricePoolSettingIndex = settingsQuery.data.findIndex(
        (setting) => setting.key === SettingEnum.PRICE_POOL
      );

      if (pricePoolSettingIndex !== -1) {
        const pricePoolSetting = Number(
          settingsQuery.data[pricePoolSettingIndex].value
        );

        setPricePool(Array.from({ length: pricePoolSetting }, (_, i) => i + 1));
      }
    }
  }, [settingsQuery.data, settingsQuery.isSuccess]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDropAccepted: handleFile,
    multiple: false,
  });

  const handleTranslationChange = (
    key: "name" | "description",
    language: string,
    value: string
  ) => {
    setData((data) => ({
      ...data,
      [key]: { ...data[key], [language]: value },
    }));
  };

  const handlePlaceChange = (event: SelectChangeEvent<typeof data.places>) => {
    const {
      target: { value },
    } = event;

    let places: number[];

    if (typeof value === "string") {
      places = value.split(",").map((place) => Number(place));
      console.log(places);
    } else {
      places = value;
    }

    setData((data) => ({ ...data, places }));
  };

  const handleSubmit = () => {
    const errors = { ...initialError };

    if (_.isEmpty(data["name"])) {
      errors.name = true;
    }

    if (_.isEmpty(data["description"])) {
      errors.description = true;
    }

    if (_.isEmpty(data["places"])) {
      errors.places = true;
    }

    if (_.isEmpty(data["image"]) && !props.data) {
      errors.image = true;
    }

    const anyError = Object.values(errors).some((error) => error);

    if (anyError) {
      setErrors(errors);
      toast.error(t("notifications.form_error"));
      return;
    }

    props.onSubmit(data);
  };

  return (
    <React.Fragment>
      <Typography marginBottom={4} variant="h5" fontWeight={700} gutterBottom>
        {t(props.title)}
      </Typography>
      <Box rowGap={4} display={"flex"} flexDirection={"column"}>
        {["name", "description"].map((key, index) => {
          const propertyKey = key as "name" | "description";

          return (
            <Card key={index}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} lg={2}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={700}
                      gutterBottom
                    >
                      {t("translations")}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      gutterBottom
                    >
                      {t(`awards.${key}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={10}>
                    {supportedLanguages.map((language, index) => (
                      <Grid key={index} container marginY={4}>
                        <Grid item xs={12} lg={5}>
                          <FormControl
                            error={language === "pl" && errors[propertyKey]}
                            fullWidth
                          >
                            <TextField
                              size="small"
                              label={t(`labels.${key}`, {
                                language: language,
                              })}
                              value={data[propertyKey][language] ?? ""}
                              InputLabelProps={{
                                shrink: !!data[propertyKey][language],
                              }}
                              onChange={(e) =>
                                handleTranslationChange(
                                  propertyKey,
                                  language,
                                  e.target.value
                                )
                              }
                            />
                            <FormHelperText>
                              {language === "pl" && errors[propertyKey] && (
                                <Typography
                                  variant={"subtitle2"}
                                  color={"error"}
                                >
                                  {t("validations.field_is_required")}
                                </Typography>
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  {t("awards.image")}
                </Typography>
              </Grid>
              <Grid item xs={12} marginY={4}>
                <Box {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>{t("dropzone.select_files")}</p>
                </Box>
                {errors.image && (
                  <Typography variant={"subtitle2"} color={"error"}>
                    {t("validations.field_is_required")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} lg={4} textAlign={"center"}>
                <img id={"image"} alt="" width={200} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={2}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  {t("awards.places")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={5} marginY={4}>
                <FormControl fullWidth error={errors.places}>
                  <InputLabel
                    sx={{ marginTop: _.isEmpty(data["places"]) ? "-6px" : 0 }}
                  >
                    {t("awards.places")}
                  </InputLabel>
                  <Select
                    multiple
                    label={t("awards.places")}
                    fullWidth
                    value={data.places}
                    onChange={handlePlaceChange}
                    size={"small"}
                    input={<OutlinedInput label={t("awards.places")} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {pricePool.map((place, index) => (
                      <MenuItem key={index} value={place}>
                        {place}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.places && (
                      <Typography variant={"subtitle2"} color={"error"}>
                        {t("validations.field_is_required")}
                      </Typography>
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box marginTop={4}>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
        >
          {t("save")}
        </Button>
      </Box>
    </React.Fragment>
  );
};

const initialError = {
  name: false,
  description: false,
  places: false,
  image: false,
};
