import React, { useEffect, useMemo } from "react";
import { Box, Button, Grid, Link, TextField } from "@mui/material";
import { LogInPropsInterface } from "features/auth/redux/types";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { LoginRequest } from "features/auth/redux/api";
import { Layout } from "features/components/layouts/auth/layout";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { authorized } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const schema: yup.Schema<LogInPropsInterface> = useMemo(() => {
    return yup.object({
      email: yup.string().required(t("validations.field_is_required")),
      password: yup.string().required(t("validations.field_is_required")),
    });
  }, [t]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LogInPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: LogInPropsInterface) => {
    dispatch(LoginRequest(data));
  };

  useEffect(() => {
    if (authorized) {
      navigate("/?rehydrate=false");
    }
  }, [authorized, navigate]);

  return (
    <React.Fragment>
      <Layout title="sign_in">
        <React.Fragment>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  required
                  fullWidth
                  id="email"
                  label={t("email_address")}
                  autoComplete="email"
                  autoFocus
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  required
                  fullWidth
                  label={t("password")}
                  type="password"
                  autoComplete="current-password"
                  {...field}
                />
              )}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("login")}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  {t("forgot_password.forgot_password")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      </Layout>
    </React.Fragment>
  );
};
