import { useTranslation } from "react-i18next";
import { AnswerInterface, FormAnswerProps } from "../../redux/types";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { supportedLanguages } from "../../../../languages/i18n";
import _ from "lodash";
import { toast } from "react-toastify";

type AnswerFormProps = {
  data?: AnswerInterface;
  onSubmit: (data: FormAnswerProps) => void;
  title: string;
  error: any;
  isError: boolean;
};

export const AnswerForm = (props: AnswerFormProps) => {
  const { t } = useTranslation();
  const [answer, setAnswer] = useState<{ [key: string]: string }>({});
  const [description, setDescription] = useState<{ [key: string]: string }>({});
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const { data, onSubmit, title } = props;

  useEffect(() => {
    if (data) {
      setAnswer(data.answer);
      setDescription(data.description);
      setIsCorrect(data.is_correct);
    }
  }, [data]);

  const handleAnswerChange = (language: string, value: string) => {
    setAnswer({ ...answer, [language]: value });
  };

  const handleDescriptionChange = (language: string, value: string) => {
    setDescription({ ...description, [language]: value });
  };

  const handleSubmit = () => {
    if (_.isEmpty(answer)) {
      toast.error(t("notifications.answer_is_required"));
      return;
    }

    onSubmit({
      answer: answer,
      description: description,
      is_correct: isCorrect,
    });
  };

  return (
    <React.Fragment>
      <Typography marginBottom={4} variant="h5" fontWeight={700} gutterBottom>
        {t(title)}
      </Typography>
      <Box rowGap={4} display={"flex"} flexDirection={"column"}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={2}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  {t("translations")}
                </Typography>
                <Typography variant="subtitle2" fontWeight={600} gutterBottom>
                  {t("answer")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={10}>
                {supportedLanguages.map((language, index) => (
                  <Grid key={index} container marginY={4}>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        size="small"
                        fullWidth
                        id={"answer_" + language}
                        label={t("labels.answer_name", { language: language })}
                        value={answer[language] ?? ""}
                        InputLabelProps={{ shrink: !!answer[language] }}
                        onChange={(e) =>
                          handleAnswerChange(language, e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={2}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  {t("translations")}
                </Typography>
                <Typography variant="subtitle2" fontWeight={600} gutterBottom>
                  {t("description")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={10}>
                {supportedLanguages.map((language, index) => (
                  <Grid key={index} container marginY={4}>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        size="small"
                        fullWidth
                        id={"description_" + language}
                        label={t("labels.description", { language: language })}
                        value={description[language] ?? ""}
                        InputLabelProps={{ shrink: !!description[language] }}
                        onChange={(e) =>
                          handleDescriptionChange(language, e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={2}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  {t("details")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isCorrect}
                      onChange={(e) => setIsCorrect(e.target.checked)}
                    />
                  }
                  label={t("labels.is_correct")}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box marginTop={4}>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
        >
          {t("save")}
        </Button>
      </Box>
    </React.Fragment>
  );
};
