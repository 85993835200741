import { useStoreAwardMutation } from "../api/repository";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { AwardForm } from "./parts/form";
import { SaveAwardPropsInterface } from "../api/types";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const AwardCreate = () => {
  const { t } = useTranslation();
  const [storeAward, data] = useStoreAwardMutation();
  const navigate = useNavigate();

  const handleSubmit = (data: SaveAwardPropsInterface) => {
    const formData = new FormData();

    Object.entries(data.name).forEach(([key, value]) => {
      formData.append(`name[${key}]`, value);
    });

    Object.entries(data.description).forEach(([key, value]) => {
      formData.append(`description[${key}]`, value);
    });

    data.places.forEach((place) => {
      formData.append("places[]", String(place));
    });

    formData.append("image", data.image as Blob, data.image?.name);

    storeAward(formData);
  };

  useEffect(() => {
    if (data.isSuccess) {
      toast.success(t("awards.created"));
      navigate("/awards");
    }
  }, [data, t]);

  return (
    <React.Fragment>
      <Box flex={1}>
        <AwardForm
          onSubmit={handleSubmit}
          error={data.error}
          isError={data.isError}
          title={"awards.create_award"}
        />
      </Box>
    </React.Fragment>
  );
};
