import { createBrowserRouter, LoaderFunctionArgs } from "react-router-dom";
import { Login } from "./features/auth/views/login";
import App from "./App";
import { CategoryList } from "./features/categories/views";
import { AuthSetting } from "./features/auth/views/settings";
import { AnswerEdit } from "./features/answers/views/edit";
import { AnswerCreate } from "./features/answers/views/create";
import { SettingList } from "./features/settings/views";
import { SettingEdit } from "./features/settings/views/edit";
import { UserList } from "./features/users/views";
import { persistConfig, store } from "./app/store";
import { Error } from "./features/errors/error";
import { getStoredState, REHYDRATE } from "redux-persist";
import { UserCreate } from "./features/users/views/create";
import { UserEdit } from "./features/users/views/edit";
import { ForgotPassword } from "./features/auth/views/forgotPassword";
import { Award } from "./features/awards/views/award";
import { UserRoleEnum } from "./features/users/api/types";
import { Question } from "./features/questions/views/question";
import { ResetPassword } from "./features/auth/views/resetPassword";

const isAuthorized = async (args: LoaderFunctionArgs) => {
  let state = await getStoredState(persistConfig);
  const { request } = args;
  const rehydarate = new URL(request.url).searchParams.get("rehydrate");

  if (!Boolean(rehydarate)) {
    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
    });

    const { authorized, user } = store.getState().auth;

    if (!authorized || user?.role !== UserRoleEnum.ADMIN) {
      throw new Response("Unauthorized", {
        status: !authorized ? 401 : 403,
        statusText: "errors.forbidden",
      });
    }
  }

  return true;
};

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/",
    element: <App />,
    loader: (state) => isAuthorized(state),
    errorElement: <Error />,
    children: [
      {
        path: "/profile/settings",
        element: <AuthSetting />,
      },
      {
        path: "/categories",
        element: <CategoryList />,
      },
      {
        path: "/questions",
        element: <Question.List />,
      },
      {
        path: "/questions/create",
        element: <Question.Create />,
      },
      {
        path: "/questions/:id",
        element: <Question.Details />,
      },
      {
        path: "/questions/:id/edit",
        element: <Question.Edit />,
      },
      {
        path: "/questions/:question_id/answers/:id/edit",
        element: <AnswerEdit />,
      },
      {
        path: "/questions/:question_id/answers/create",
        element: <AnswerCreate />,
      },
      {
        path: "/awards",
        element: <Award.List />,
      },
      {
        path: "/awards/create",
        element: <Award.Create />,
      },
      {
        path: "/awards/:id/edit",
        element: <Award.Edit />,
      },
      {
        path: "/awards/history",
        element: <Award.History />,
      },
      {
        path: "/settings",
        element: <SettingList />,
      },
      {
        path: "/settings/:id/edit",
        element: <SettingEdit />,
      },
      {
        path: "/users",
        element: <UserList />,
      },
      {
        path: "/users/create",
        element: <UserCreate />,
      },
      {
        path: "/users/:id/edit",
        element: <UserEdit />,
      },
    ],
  },
]);
