import { useTranslation } from "react-i18next";
import {
  QuestionInterface,
  QuestionLevelEnum,
  SaveQuestionPropsInterface,
} from "../../redux/types";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import i18n, { supportedLanguages } from "../../../../languages/i18n";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetCategoriesQuery } from "../../../categories/redux/api";
import _ from "lodash";
import { toast } from "react-toastify";

type CategoryFormProps = {
  onSubmit: (data: SaveQuestionPropsInterface) => void;
  error: any;
  isError: boolean;
  data?: QuestionInterface;
  title: string;
};

type SaveQuestionPropsWithoutQuestion = Omit<
  SaveQuestionPropsInterface,
  "question"
>;

export const QuestionForm = (props: CategoryFormProps) => {
  const { t } = useTranslation();
  const { title, onSubmit, data } = props;
  const [name, setName] = useState<{ [key: string]: string }>({});
  const { data: categoryData = [] } = useGetCategoriesQuery();
  const currentLanguage = i18n.language;

  const handleTranslationChange = (language: string, value: string) => {
    setName({ ...name, [language]: value });
  };

  const schema: yup.Schema<SaveQuestionPropsWithoutQuestion> = useMemo(() => {
    return yup.object({
      category_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
      level: yup
        .mixed<QuestionLevelEnum>()
        .oneOf(Object.values(QuestionLevelEnum))
        .required(t("validations.field_is_required")),
    });
  }, [t]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SaveQuestionPropsWithoutQuestion>({
    resolver: yupResolver(schema),
    defaultValues: {
      level: QuestionLevelEnum.EASY,
      category_id: 0,
    },
  });

  useEffect(() => {
    if (data) {
      setName(data.question);
      setValue("level", data.level);
      setValue("category_id", data.question_category_id);
    }
  }, [data, setValue]);

  const processSubmit = (data: SaveQuestionPropsWithoutQuestion) => {
    if (_.isEmpty(name)) {
      toast.warning(t("notifications.question_name_is_required"));
      return;
    }

    onSubmit({
      ...data,
      question: name,
    });
  };

  return (
    <React.Fragment>
      <Typography marginBottom={4} variant="h5" fontWeight={700} gutterBottom>
        {t(title)}
      </Typography>
      <form onSubmit={handleSubmit(processSubmit)}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={2}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  {t("translations")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={10}>
                {supportedLanguages.map((language, index) => (
                  <Grid key={index} container marginY={4}>
                    <Grid item xs={12} lg={5}>
                      <TextField
                        size="small"
                        fullWidth
                        id={"question_" + language}
                        label={t("labels.question", { language: language })}
                        defaultValue={""}
                        value={name[language]}
                        InputLabelProps={{ shrink: !!name[language] }}
                        onChange={(e) =>
                          handleTranslationChange(language, e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ marginTop: 5 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={2}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  {t("details")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box display={"flex"} rowGap={3} flexDirection={"column"}>
                  <FormControl error={!!errors.category_id}>
                    <InputLabel id="demo-simple-select-standard-label">
                      {t("labels.category")}
                    </InputLabel>
                    <Controller
                      name="category_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          size="small"
                          label={t("labels.category")}
                          fullWidth
                          {...field}
                        >
                          <MenuItem value={0}>
                            {t("labels.select_category")}
                          </MenuItem>
                          {categoryData.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name[currentLanguage]}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>
                      {errors.category_id?.message}
                    </FormHelperText>
                  </FormControl>
                  <FormControl error={!!errors.level}>
                    <InputLabel id="demo-simple-select-standard-label">
                      {t("labels.level")}
                    </InputLabel>
                    <Controller
                      name="level"
                      control={control}
                      render={({ field }) => (
                        <Select
                          size="small"
                          label={t("labels.level")}
                          fullWidth
                          error={!!errors.level}
                          {...field}
                        >
                          {Object.values(QuestionLevelEnum).map((level) => (
                            <MenuItem key={level} value={level}>
                              {t(`question_levels.${level}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>{errors.level?.message}</FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box marginTop={4}>
          <Button type="submit" variant="contained" color="primary">
            {t("save")}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};
