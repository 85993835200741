import { FormProps } from "app/types";
import {
  SaveUserPropsInterface,
  UserInterface,
  UserRoleEnum,
  UserSexEnum,
} from "../../api/types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import React, { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export const UserForm = (
  props: FormProps<SaveUserPropsInterface, UserInterface>
) => {
  const { t } = useTranslation();

  const schema: yup.ObjectSchema<SaveUserPropsInterface> = useMemo(() => {
    return yup.object({
      username: yup.string().required(t("validations.field_is_required")),
      email: yup
        .string()
        .email(t("validations.field_must_be_email"))
        .required(t("validations.field_is_required")),
      password: yup
        .string()
        .required(t("validations.field_is_required"))
        .min(6, t("validations.field_must_be_at_least_characters", { min: 6 })),
      password_confirmation: yup
        .string()
        .required(t("validations.field_is_required"))
        .oneOf([yup.ref("password")], t("validations.passwords_must_match")),
      role: yup
        .mixed<UserRoleEnum>()
        .oneOf(Object.values(UserRoleEnum))
        .required(t("validations.field_is_required")),
      sex: yup
        .mixed<UserSexEnum>()
        .oneOf(Object.values(UserSexEnum))
        .required(t("validations.field_is_required")),
      date_of_birth: yup.string().required(t("validations.field_is_required")),
    });
  }, [t]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SaveUserPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
      username: "",
      role: UserRoleEnum.USER,
      sex: UserSexEnum.MALE,
      date_of_birth: "",
    },
  });

  useEffect(() => {
    if (props.data) {
      Object.entries(props.data).forEach(([key, value]) => {
        setValue(key as keyof SaveUserPropsInterface, value);
      });
    }
  }, [props.data, setValue]);

  return (
    <React.Fragment>
      <Typography marginBottom={4} variant="h5" fontWeight={700} gutterBottom>
        {t(props.title)}
      </Typography>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Box rowGap={4} display={"flex"} flexDirection={"column"}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} lg={3}>
                  <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                    {t("basic_data")}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={9} marginY={4}>
                  <Grid container>
                    <Grid item xs={12} lg={5} display={"flex"}>
                      <Box
                        flex={1}
                        rowGap={3}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <FormControl fullWidth error={!!errors.email}>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                variant={"outlined"}
                                label={t("labels.email")}
                                size={"small"}
                                fullWidth
                                {...field}
                                InputLabelProps={{ shrink: !!field.value }}
                              />
                            )}
                            name={"email"}
                            control={control}
                          />
                          <FormHelperText>
                            {errors.email?.message}
                          </FormHelperText>
                        </FormControl>
                        <FormControl fullWidth error={!!errors.username}>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                variant={"outlined"}
                                label={t("labels.username")}
                                size={"small"}
                                {...field}
                                InputLabelProps={{ shrink: !!field.value }}
                              />
                            )}
                            name={"username"}
                            control={control}
                          />
                          <FormHelperText>
                            {errors.username?.message}
                          </FormHelperText>
                        </FormControl>
                        <FormControl fullWidth error={!!errors.password}>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                type={"password"}
                                variant={"outlined"}
                                label={t("labels.password")}
                                size={"small"}
                                {...field}
                                InputLabelProps={{ shrink: !!field.value }}
                              />
                            )}
                            name={"password"}
                            control={control}
                          />
                          <FormHelperText>
                            {errors.password?.message}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          fullWidth
                          error={!!errors.password_confirmation}
                        >
                          <Controller
                            render={({ field }) => (
                              <TextField
                                type={"password"}
                                variant={"outlined"}
                                label={t("labels.password_confirmation")}
                                size={"small"}
                                {...field}
                                InputLabelProps={{ shrink: !!field.value }}
                              />
                            )}
                            name={"password_confirmation"}
                            control={control}
                          />
                          <FormHelperText>
                            {errors.password_confirmation?.message}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} lg={3}>
                  <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                    {t("additional_data")}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={9} marginY={4}>
                  <Grid container>
                    <Grid item xs={12} lg={5} display={"flex"}>
                      <Box
                        flex={1}
                        rowGap={3}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <FormControl fullWidth error={!!errors.role}>
                          <InputLabel>{t("labels.role")}</InputLabel>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                size={"small"}
                                label={t("labels.role")}
                              >
                                {Object.values(UserRoleEnum).map((role) => (
                                  <MenuItem key={role} value={role}>
                                    {t(`users.roles.${role}`)}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                            name={"role"}
                            control={control}
                          />
                          <FormHelperText>
                            {errors.role?.message}
                          </FormHelperText>
                        </FormControl>
                        <FormControl fullWidth error={!!errors.sex}>
                          <InputLabel>{t("labels.sex")}</InputLabel>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                size={"small"}
                                label={t("labels.sex")}
                              >
                                {Object.values(UserSexEnum).map((sex) => (
                                  <MenuItem key={sex} value={sex}>
                                    {t(`users.sex.${sex}`)}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                            name={"sex"}
                            control={control}
                          />
                          <FormHelperText>{errors.sex?.message}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth error={!!errors.date_of_birth}>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                type={"date"}
                                variant={"outlined"}
                                label={t("labels.date_of_birth")}
                                size={"small"}
                                {...field}
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                            name={"date_of_birth"}
                            control={control}
                          />
                          <FormHelperText>
                            {errors.date_of_birth?.message}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box marginTop={4}>
          <Button variant="contained" color="primary" type={"submit"}>
            {t("save")}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};
