import { useTranslation } from "react-i18next";
import { Layout } from "../../components/layouts/auth/layout";
import * as yup from "yup";
import React, { useEffect, useMemo } from "react";
import { ForgotPasswordPropsInterface } from "../redux/types";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Box, Button, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ForgotPasswordRequest } from "../redux/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { error, status } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const schema: yup.Schema<ForgotPasswordPropsInterface> = useMemo(() => {
    return yup.object({
      email: yup
        .string()
        .email(t("validations.field_must_be_email"))
        .required(t("validations.field_is_required")),
    });
  }, [t]);

  const onSubmit = (data: ForgotPasswordPropsInterface) => {
    dispatch(ForgotPasswordRequest(data));
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (error && error.code && status === "failed") {
      const errorCode = error.code as number;

      switch (errorCode) {
        case 404:
          toast.warning(t("forgot_password.not_found"));
          return;
        case 429:
          toast.warning(t("notifications.too_many_requests"));
          return;
      }
    }

    if (status === "success") {
      toast.success(t("forgot_password.success"));
      navigate("/reset-password");
    }
  }, [error, navigate, status, t]);

  return (
    <Layout title="forgot_password.title">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        width={"100%"}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              margin="normal"
              error={!!errors.email}
              helperText={errors.email?.message}
              required
              fullWidth
              label={t("email_address")}
              autoComplete="email"
              autoFocus
              {...field}
            />
          )}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t("send")}
        </Button>
      </Box>
    </Layout>
  );
};
