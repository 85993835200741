import {
  ForgotPasswordRequest,
  LoginRequest,
  LogoutRequest,
  ResetPasswordRequest,
  UpdatePasswordRequest,
} from "./api";
import { createSlice, isPending } from "@reduxjs/toolkit";
import { AuthStateInterface } from "./types";
import { UserInterface } from "../../users/api/types";

const initialState: AuthStateInterface = {
  bearerToken: null,
  authorized: false,
  loading: false,
  errorMessage: null,
  status: "idle",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(LoginRequest.fulfilled, (state, action) => {
      state.user = action.payload.data.user;
      state.bearerToken = action.payload.data.token;
      state.authorized = true;
      state.loading = false;
    });
    builder.addCase(UpdatePasswordRequest.fulfilled, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(ForgotPasswordRequest.fulfilled, (state, action) => {
      state.status = "success";
      state.user = {
        email: action.meta.arg.email,
      } as UserInterface;
    });
    builder.addCase(ForgotPasswordRequest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(ResetPasswordRequest.fulfilled, (state, action) => {
      if (action.payload.code !== 200) {
        state.status = "failed";
        state.error = {
          code: action.payload.code,
        };
      } else {
        state.status = "success";
        state.user = action.payload.data.user;
        state.bearerToken = action.payload.data.token;
        state.authorized = true;
        state.loading = false;
      }
    });
    builder.addCase(LogoutRequest.fulfilled, (state) => {
      state = {
        ...initialState,
        status: "success",
      };

      return state;
    });
    builder.addMatcher(isPending, (state) => {
      state.loading = true;
      state.status = "idle";
    });
  },
});

export default authSlice.reducer;
