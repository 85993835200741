import { useNavigate, useParams } from "react-router-dom";
import { useGetQuestionQuery, useUpdateQuestionMutation } from "../redux/api";
import { Box } from "@mui/material";
import { SaveQuestionPropsInterface } from "../redux/types";
import { QuestionForm } from "./parts/form";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const QuestionEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useGetQuestionQuery(id!);
  const [updateQuestion, { isSuccess }] = useUpdateQuestionMutation();
  const navigate = useNavigate();

  const onSubmit = (data: SaveQuestionPropsInterface) => {
    updateQuestion({ id: id!, ...data });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("questions.updated");
      navigate("/questions");
    }
  }, [isSuccess, navigate]);

  return (
    <Box flex={1}>
      <QuestionForm
        onSubmit={onSubmit}
        title="questions.update_question"
        error={false}
        isError={false}
        data={data}
      />
    </Box>
  );
};
