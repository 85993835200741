import { PaginationInterface, SuccessResponseInterface } from "app/types";

export interface UserInterface {
  id: string;
  username: string;
  email: string;
  date_of_birth: string | null;
  sex: UserSexEnum | null;
  role: UserRoleEnum;
}

export interface DeliveryAddressInterface {
  id: number;
  additional_information: string;
  city: string;
  apartment_number: string;
  street: string;
  province: string;
  postal_code: string;
  is_default: boolean;
  house_number: string;
}

export enum UserSexEnum {
  MALE = "male",
  FEMALE = "female",
}

export enum UserRoleEnum {
  USER = "user",
  ADMIN = "admin",
}

export interface GetUsersSuccessResponse extends SuccessResponseInterface {
  data: {
    data: UserInterface[];
    pagination: PaginationInterface;
  };
}

export interface GetUserSuccessResponse extends SuccessResponseInterface {
  data: UserInterface;
}

export type SaveUserPropsInterface = Omit<UserInterface, "id"> & {
  password: string;
  password_confirmation: string;
};
