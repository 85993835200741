import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import backgroundImage from "assets/images/login-background.svg";

type LayoutProps = {
  title: string;
  children: React.ReactNode;
};

export const Layout = (props: LayoutProps) => {
  const { title, children } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flex: 1,
        }}
      >
        <Container component="main" maxWidth={"sm"}>
          <Card
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              borderRadius: 5,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              style={{
                padding: 80,
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5" marginBottom={4}>
                {t(title)}
              </Typography>
              {children}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </React.Fragment>
  );
};
