import { quizApi } from "../../../app/quizApi";
import {
  GetAwardsSuccessResponseInterface,
  GetAwardSuccessResponseInterface,
  GetHistoryPropsInterface,
  GetHistorySuccessResponseInterface,
} from "./types";
import { PaginationPropsInterface } from "../../../app/types";

export const awardRepository = quizApi.injectEndpoints({
  endpoints: (builder) => ({
    getAwards: builder.query<
      GetAwardsSuccessResponseInterface["data"],
      PaginationPropsInterface
    >({
      query: (pagination) => ({
        url: "/api/panel/awards",
        params: pagination,
      }),
      transformResponse: (response: GetAwardsSuccessResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Awards", id } as const)),
              { type: "Awards", id: "LIST" },
            ]
          : [{ type: "Awards", id: "LIST" }],
    }),
    getAward: builder.query<
      GetAwardSuccessResponseInterface["data"],
      string | number
    >({
      query: (id) => `/api/panel/awards/${id}`,
      transformResponse: (response: GetAwardSuccessResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "Awards", id: id }],
    }),
    storeAward: builder.mutation<void, FormData>({
      query: (data) => ({
        url: "/api/panel/awards",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [{ type: "Awards", id: "LIST" }],
    }),
    updateAward: builder.mutation<
      void,
      { data: FormData; id: number | string }
    >({
      query: ({ id, data }) => ({
        url: `/api/panel/awards/${id}`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Awards", id: id },
        { type: "Awards", id: "LIST" },
      ],
    }),
    getHistory: builder.query<
      GetHistorySuccessResponseInterface["data"],
      GetHistoryPropsInterface
    >({
      query: (props) => ({
        url: "/api/panel/rankings",
        params: props,
      }),
      transformResponse: (response: GetHistorySuccessResponseInterface) =>
        response.data,
    }),
  }),
});

export const {
  useGetAwardsQuery,
  useGetAwardQuery,
  useStoreAwardMutation,
  useUpdateAwardMutation,
  useGetHistoryQuery,
} = awardRepository;
