import { useTranslation } from "react-i18next";
import { useStoreUserMutation } from "../api/repository";
import { SaveUserPropsInterface } from "../api/types";
import { Box } from "@mui/material";
import { UserForm } from "./parts/form";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DetailsSkeleton } from "../../components/skeletons/details";

export const UserCreate = () => {
  const { t } = useTranslation();
  const [storeUser, { error, isError, isSuccess, isLoading }] =
    useStoreUserMutation();
  const navigate = useNavigate();

  const handleSubmit = (data: SaveUserPropsInterface) => {
    storeUser(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("users.created"));
      navigate("/users");
    }
  }, [isSuccess, navigate, t]);

  return (
    <Box flex={1}>
      {isLoading ? (
        <DetailsSkeleton />
      ) : (
        <UserForm
          onSubmit={handleSubmit}
          error={error}
          isError={isError}
          title={"users.create"}
        />
      )}
    </Box>
  );
};
