import { useTranslation } from "react-i18next";
import { useGetQuestionQuery } from "../redux/api";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DetailsSkeleton } from "../../components/skeletons/details";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import i18n from "../../../languages/i18n";
import { AnswerList } from "../../answers/views";
import { AnswerInterface } from "../../answers/redux/types";

export const QuestionDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data, isLoading } = useGetQuestionQuery(id!);
  const [answers, setAnswers] = useState<AnswerInterface[]>([]);
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (data && data.answers) {
      setAnswers(data.answers);
    }
  }, [data]);

  return (
    <React.Fragment>
      {isLoading || !data ? (
        <DetailsSkeleton />
      ) : (
        <Box flex={1}>
          <Typography variant="h5" fontWeight={700}>
            {t("question_details")}
          </Typography>
          <Typography variant="subtitle1" fontWeight={500}>
            {data.question[currentLanguage]}
          </Typography>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} md={8} lg={6}>
              <Card>
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    fontWeight={700}
                    paddingBottom={5}
                  >
                    {t("question_details")}
                  </Typography>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th">
                          {t("translations")}
                        </TableCell>
                        <TableCell>
                          {Object.keys(data.question).map((key, index) => (
                            <Box sx={{ marginY: 2 }}>
                              <Typography
                                sx={{ marginRight: 2 }}
                                variant="subtitle2"
                              >
                                {t("translation_for_language", {
                                  language: key,
                                })}
                              </Typography>
                              <Typography variant="body2">
                                {data.question[key]}
                              </Typography>
                            </Box>
                          ))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">
                          {t("labels.level")}
                        </TableCell>
                        <TableCell>
                          {t(`question_levels.${data.level}`)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">
                          {t("category_name")}
                        </TableCell>
                        <TableCell>
                          {data.question_category.name[currentLanguage]}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <AnswerList answers={answers} />
        </Box>
      )}
    </React.Fragment>
  );
};
