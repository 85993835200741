import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { TableSkeleton } from "../../components/skeletons/table";
import { ReactTable, TableColumn } from "../../components/table";
import { useGetAwardsQuery } from "../api/repository";
import { AwardInterface } from "../api/types";
import { ActionColumn } from "../../components/actionColumn";

export const AwardList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [{ page, perPage }, setPagination] = useState({
    page: 0,
    perPage: 15,
  });
  const { data, isLoading } = useGetAwardsQuery({
    page,
    per_page: perPage,
  });
  const [awards, setAwards] = useState<AwardInterface[]>([]);
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (data) {
      setAwards(data.data);
    }
  }, [data]);

  const columns: TableColumn<AwardInterface>[] = useMemo(
    () => [
      {
        header: "column_names.name",
        cell: (row) => row.name[currentLanguage],
      },
      {
        header: "column_names.description",
        cell: (row) => row.description[currentLanguage],
      },
      {
        header: "column_names.places",
        cell: (row) => row.places.join(", "),
      },
      {
        cell: (row) => <ActionColumn editLink={`/awards/${row.id}/edit`} />,
      },
    ],
    [currentLanguage]
  );

  return (
    <React.Fragment>
      <Box flex={1}>
        <Box
          paddingBottom={4}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography fontWeight={700} variant="h5">
            {t("awards.title")}
          </Typography>
          <Box display={"flex"} gap={2}>
            <Button
              variant={"contained"}
              onClick={() => navigate("/awards/create")}
              color={"primary"}
            >
              {t("awards.create")}
            </Button>
            <Button
              variant={"contained"}
              onClick={() => navigate("/awards/history")}
              color={"primary"}
            >
              {t("awards.history")}
            </Button>
          </Box>
        </Box>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Card>
            <CardContent>
              <ReactTable
                data={awards}
                columns={columns}
                paginated
                perPage={perPage}
                currentPage={page}
                count={data?.pagination.total}
                onPaginate={setPagination}
              />
            </CardContent>
          </Card>
        )}
      </Box>
    </React.Fragment>
  );
};
