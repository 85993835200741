import { PaginationPropsInterface } from "./../../../app/types";
import { quizApi } from "./../../../app/quizApi";
import {
  GetQuestionSuccessResponseInterface,
  GetQuestionsSuccessResponseInterface,
  QuestionInterface,
  SaveQuestionPropsInterface,
} from "./types";

export const questionApi = quizApi.injectEndpoints({
  endpoints: (build) => ({
    getQuestions: build.query<
      GetQuestionsSuccessResponseInterface["data"],
      PaginationPropsInterface
    >({
      query: (pagination) => ({
        url: "/api/panel/questions",
        params: pagination,
      }),
      transformResponse: (response: GetQuestionsSuccessResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "Questions", id } as const)
              ),
              { type: "Questions", id: "LIST" },
            ]
          : [{ type: "Questions", id: "LIST" }],
    }),
    getQuestion: build.query<QuestionInterface, number | string>({
      query: (id) => `/api/panel/questions/${id}`,
      transformResponse: (response: GetQuestionSuccessResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "Questions", id }],
    }),
    storeQuestion: build.mutation<void, SaveQuestionPropsInterface>({
      query: (body) => ({
        url: "/api/panel/questions",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Questions", id: "LIST" }],
    }),
    updateQuestion: build.mutation<
      void,
      SaveQuestionPropsInterface & { id: number | string }
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/questions/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Questions", id }],
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useGetQuestionQuery,
  useStoreQuestionMutation,
  useUpdateQuestionMutation,
} = questionApi;
