import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";

type ActionColumnProps = {
  detailsLink?: string;
  editLink?: string;
  onDeleteClick?: () => void;
};

export const ActionColumn = (props: ActionColumnProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          sx: { width: 120 },
        }}
      >
        {props.detailsLink && (
          <MenuItem component={"a"} href={props.detailsLink}>
            {t("action_column.show")}
          </MenuItem>
        )}
        {props.editLink && (
          <MenuItem component={"a"} href={props.editLink}>
            {t("action_column.edit")}
          </MenuItem>
        )}
        {props.onDeleteClick && (
          <MenuItem onClick={props.onDeleteClick}>
            {t("action_column.delete")}
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};
