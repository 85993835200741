import {
  CategoryInterface,
  GetCategoriesSuccessResponse,
  GetCategorySuccessResponse,
  SaveCategoryPropsInterface,
} from "./types";
import { quizApi } from "../../../app/quizApi";

export const categoryApi = quizApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<CategoryInterface[], void>({
      query: () => "/api/panel/question-categories",
      transformResponse: (response: GetCategoriesSuccessResponse) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Categories", id } as const)),
              { type: "Categories", id: "LIST" },
            ]
          : [{ type: "Categories", id: "LIST" }],
    }),
    getCategory: builder.query<CategoryInterface, string | number>({
      query: (id) => `/api/panel/question-categories/${id}`,
      transformResponse: (response: GetCategorySuccessResponse) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "Categories", id: id }],
    }),
    storeCategory: builder.mutation<void, SaveCategoryPropsInterface>({
      query: (data) => ({
        url: "/api/panel/question-categories",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    updateCategory: builder.mutation<
      void,
      SaveCategoryPropsInterface & { id: string | number }
    >({
      query: (data) => ({
        url: `/api/panel/question-categories/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Categories", id: "LIST" },
        { type: "Categories", id: id },
      ],
    }),
    deleteCategory: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `/api/panel/question-categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useStoreCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;
