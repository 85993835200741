import { useEffect, useState } from "react";
import { AnswerInterface } from "../redux/types";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import i18n from "../../../languages/i18n";
import { MoreHoriz } from "@mui/icons-material";
import React from "react";
import _ from "lodash";
import { useDeleteAnswerMutation } from "../redux/api";
import { toast } from "react-toastify";

type AnswerDetailsProps = {
  answer: AnswerInterface;
};

export const AnswerDetails = (props: AnswerDetailsProps) => {
  const { answer } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const [deleteAnswer, { isSuccess }] = useDeleteAnswerMutation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteAnswer({
      id: answer.id,
      question_id: answer.question_id,
    });

    handleClose();
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("notifications.answer_deleted"));
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Box columnGap={2} display="flex">
            <Avatar
              sx={{
                backgroundColor: answer.is_correct
                  ? "success.main"
                  : "error.main",
              }}
            >
              {answer.is_correct ? <CheckIcon /> : <CloseIcon />}
            </Avatar>
            <Box display="flex" flexWrap={"wrap"} alignContent={"center"}>
              <Typography variant="subtitle2">
                {answer.answer[currentLanguage]}
              </Typography>
            </Box>
            <Box flexGrow={1} />
            <IconButton onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          </Box>
          {!_.isEmpty(answer.description) && (
            <React.Fragment>
              <Box
                marginTop={2}
                display={"flex"}
                flexDirection={"column"}
                rowGap={1}
              >
                <Typography variant="subtitle2" fontWeight={600}>
                  {t("description")}
                </Typography>
                <Typography variant="body2">
                  {answer.description[currentLanguage]}
                </Typography>
              </Box>
            </React.Fragment>
          )}
        </CardContent>
      </Card>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          sx: { width: 120 },
        }}
      >
        <MenuItem
          component={Link}
          href={`/questions/${answer.question_id}/answers/${answer.id}/edit`}
        >
          {t("action_column.edit")}
        </MenuItem>
        <MenuItem onClick={handleDelete}>{t("action_column.delete")}</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
