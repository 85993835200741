import { useTranslation } from "react-i18next";
import { useGetHistoryQuery } from "../api/repository";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { GetHistoryPropsInterface, RankingInterface } from "../api/types";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { ReactTable, TableColumn } from "../../components/table";

export const AwardHistory = () => {
  const { t } = useTranslation();
  const [{ date, price_pool }, setParams] = useState<GetHistoryPropsInterface>({
    date: new Date().toISOString(),
    price_pool: 10,
  });
  const { data } = useGetHistoryQuery({ date, price_pool });
  const [positions, setPositions] = useState<RankingInterface[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<RankingInterface>();

  useEffect(() => {
    if (data) {
      setPositions(data);
    }
  }, [data]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: keyof GetHistoryPropsInterface
  ) => {
    setParams((prev) => ({ ...prev, [param]: e.target.value }));
  };

  const onUserClick = (user: RankingInterface) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const columns: TableColumn<RankingInterface>[] = useMemo(
    () => [
      {
        header: "column_names.position",
        accessor: "position",
      },
      {
        header: "column_names.username",
        cell: (row) => (
          <Button variant={"text"} onClick={() => onUserClick(row)}>
            {row.username}
          </Button>
        ),
      },
      {
        header: "column_names.points",
        accessor: "points",
      },
      {
        header: "column_names.time_spent",
        cell: (row) => calculateTime(row.time_spent),
      },
    ],
    []
  );

  const calculateTime = (timeSpent: number) => {
    let minutes = Math.floor(timeSpent / 60);
    let secondsLeft = timeSpent % 60;
    return minutes + ":" + (secondsLeft < 10 ? "0" : "") + secondsLeft;
  };

  return (
    <React.Fragment>
      <Box flex={1}>
        <Typography fontWeight={700} variant="h5">
          {t("awards.history_title")}
        </Typography>
        <Card sx={{ marginTop: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    size={"small"}
                    label={t("labels.date")}
                    value={date}
                    onChange={(e) => handleChange(e, "date")}
                    type="date"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <TextField
                    size={"small"}
                    label={t("labels.price_pool")}
                    value={price_pool}
                    type={"number"}
                    aria-valuemin={1}
                    aria-valuemax={20}
                    onChange={(e) => handleChange(e, "price_pool")}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container marginTop={4}>
              <Grid item xs={12}>
                <ReactTable data={positions} columns={columns} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
            width: 600,
            height: 200,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
          }}
        >
          <Box display={"flex"} padding={2} flexDirection={"column"}>
            <Typography marginBottom={4} variant={"h5"} fontWeight={700}>
              {selectedUser?.username}
            </Typography>
            <Typography fontWeight={700}>{t("awards.address")}</Typography>
            <Typography marginTop={2}>
              {selectedUser?.delivery_address?.city}{" "}
              {selectedUser?.delivery_address?.postal_code}{" "}
              {selectedUser?.delivery_address?.street}{" "}
              {selectedUser?.delivery_address?.house_number} /{" "}
              {selectedUser?.delivery_address?.apartment_number}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
