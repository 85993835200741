import React from "react";
import "./App.css";
import { Layout } from "./features/components/layouts/app/layout";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <React.Fragment>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.8);",
        }}
        display={"flex"}
        flexDirection={"column"}
      >
        <Layout />
        <Box display={"flex"} sx={{ paddingLeft: "280px" }}>
          <Box display={"flex"} flex={1} paddingX={5} paddingBottom={5}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default App;
