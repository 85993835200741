import { useTranslation } from "react-i18next";
import { useGetQuestionsQuery } from "../redux/api";
import React, { useEffect, useState } from "react";
import { QuestionInterface } from "../redux/types";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { TableSkeleton } from "../../components/skeletons/table";
import { ReactTable, TableColumn } from "../../components/table";
import i18n from "../../../languages/i18n";
import { ActionColumn } from "../../components/actionColumn";
import { useNavigate } from "react-router-dom";

export const QuestionList = () => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<QuestionInterface[]>([]);
  const [{ page, perPage }, setPagination] = useState({
    page: 0,
    perPage: 15,
  });
  const { data, isLoading } = useGetQuestionsQuery({
    page: page + 1,
    per_page: perPage,
  });
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setQuestions(data.data);
    }
  }, [data]);

  const columns: TableColumn<QuestionInterface>[] = [
    {
      header: "question",
      cell: (row) => row.question[currentLanguage],
    },
    {
      header: "category_name",
      cell: (row) => row.question_category.name[currentLanguage],
    },
    {
      header: "level",
      cell: (row) => t("question_levels." + row.level),
    },
    {
      cell: (row) => (
        <ActionColumn
          editLink={`/questions/${row.id}/edit`}
          detailsLink={`/questions/${row.id}`}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <Box flex={1} flexDirection={"column"}>
        <Typography marginBottom={4} fontWeight={700} variant="h5">
          {t("questions.list")}
        </Typography>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <React.Fragment>
            <Box flex={1} display={"flex"} flexDirection={"column"} rowGap={5}>
              <Box display={"flex"} flexDirection={"row-reverse"}>
                <Button
                  color={"primary"}
                  variant={"contained"}
                  onClick={() => navigate("/questions/create")}
                >
                  {t("questions.create")}
                </Button>
              </Box>
              <Card>
                <CardContent>
                  <ReactTable
                    paginated
                    perPage={perPage}
                    currentPage={page}
                    count={data?.pagination.total}
                    data={questions}
                    columns={columns}
                    onPaginate={setPagination}
                  />
                </CardContent>
              </Card>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};
