import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAwardQuery, useUpdateAwardMutation } from "../api/repository";
import { Box } from "@mui/material";
import { AwardForm } from "./parts/form";
import { SaveAwardPropsInterface } from "../api/types";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const AwardEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error, isError } = useGetAwardQuery(id!);
  const [updateAward, updateData] = useUpdateAwardMutation();
  const navigate = useNavigate();

  const handleSubmit = (data: SaveAwardPropsInterface) => {
    const formData = new FormData();

    Object.entries(data.name).forEach(([key, value]) => {
      formData.append(`name[${key}]`, value);
    });

    Object.entries(data.description).forEach(([key, value]) => {
      formData.append(`description[${key}]`, value);
    });

    data.places.forEach((place) => {
      formData.append("places[]", String(place));
    });

    if (data.image) {
      formData.append("image", data.image as Blob, data.image?.name);
    }

    formData.append("_method", "PUT");

    updateAward({
      id: id!,
      data: formData,
    });
  };

  useEffect(() => {
    if (updateData.isSuccess) {
      toast.success(t("awards.updated"));
      navigate("/awards");
    }
  }, [navigate, t, updateData.isSuccess]);

  return (
    <Box flex={1}>
      <AwardForm
        onSubmit={handleSubmit}
        error={error}
        isError={isError}
        title={"awards.edit_award"}
        data={data}
      />
    </Box>
  );
};
