import React, { useState } from "react";
import { Sidebar } from "./sidebar";
import { Navbar } from "./navbar";

export const Layout = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Navbar onOpen={() => setOpen(!open)} />
      <Sidebar onClose={() => setOpen(!open)} open={open} />
    </React.Fragment>
  );
};
