import { Dashboard, VerifiedUserSharp } from "@mui/icons-material";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CategoryIcon from "@mui/icons-material/Category";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation } from "react-router-dom";

type SidebarProps = {
  open: boolean;
  onClose: () => void;
};

export const Sidebar = (props: SidebarProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isCurrentRoute = (path?: string) => {
    if (!path) return false;

    return new RegExp(`^${path}(?:\\/([\\w-]+))?$`).test(location.pathname);
  };

  return (
    <Box display={"flex"}>
      <Paper
        variant="outlined"
        sx={{
          position: "fixed",
          flexDirection: "column",
          top: 0,
          left: 0,
          height: "100%",
          width: 280,
          backgroundColor: "primary.main",
          borderRadius: 0,
        }}
      >
        <MenuList sx={{ paddingX: "16px" }}>
          <MenuItem sx={{ padding: "24px", color: "white" }}>LOGO</MenuItem>
          {menuItems.map((item, index) => (
            <MenuItem
              sx={{
                marginY: 1,
                ":hover": item.path && activeStyle,
                ...(isCurrentRoute(item.path) && activeStyle),
              }}
              key={index}
              component={item.path ? "a" : "span"}
              href={item.path}
            >
              {item.icon && (
                <ListItemIcon
                  sx={{
                    color: isCurrentRoute(item.path) ? "#6366F1" : "#9DA4AE",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              )}
              <ListItemText>
                <Typography
                  sx={{
                    ...fontStyle,
                    color: isCurrentRoute(item.path) ? "#fff" : "#9DA4AE",
                    textTransform: !item.path ? "uppercase" : "inherit",
                  }}
                >
                  {t(item.name)}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </Box>
  );
};

type MenuItem = {
  name: string;
  icon?: React.ReactNode;
  path?: string;
};

const menuItems: MenuItem[] = [
  {
    name: "sidebar_menus.dashboard",
    icon: <Dashboard fontSize="small" />,
    path: "/",
  },
  {
    name: "sidebar_menus.awards",
    icon: <EmojiEventsIcon fontSize="small" />,
    path: "/awards",
  },
  {
    name: "sidebar_menus.settings",
    icon: <SettingsIcon fontSize="small" />,
    path: "/settings",
  },
  {
    name: "sidebar_menus.users",
    path: "/users",
    icon: <VerifiedUserSharp fontSize="small" />,
  },
  {
    name: "sidebar_menus.questions",
  },
  {
    name: "sidebar_menus.categories",
    path: "/categories",
    icon: <CategoryIcon fontSize="small" />,
  },
  {
    name: "sidebar_menus.questions",
    path: "/questions",
    icon: <QuestionMarkIcon fontSize="small" />,
  },
];

const fontStyle = {
  fontWeight: 600,
  lineHeight: "24px",
  fontSize: "14px",
};

const activeStyle = {
  backgroundColor: "rgba(255, 255, 255, 0.04);",
  borderRadius: "10px",
};
