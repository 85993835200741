import { RootState } from "./store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const url = process.env.REACT_APP_BACKEND_URL;

export const quizApi = createApi({
  reducerPath: "quizApi",
  tagTypes: [
    "Categories",
    "Questions",
    "Answers",
    "Awards",
    "Settings",
    "Users",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.bearerToken;

      headers.set("Accept", "application/json");

      if (headers.get("Content-Type") === "multipart/form-data") {
        headers.delete("Content-Type");
      } else {
        headers.set("Content-Type", "application/json");
      }

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});
