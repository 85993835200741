import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ForbiddenError } from "./forbiddenError";
import { InternalError } from "./internalError";
import { Box } from "@mui/material";

export const Error = () => {
  const error = useRouteError();
  const [errorCode, setErrorCode] = useState<number>(500);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      setErrorCode(error.status);

      if (error.status === 401) {
        navigate("/login");
      }
    }
  }, [error]);

  return (
    <Box display={"flex"}>
      {errorCode === 403 ? <ForbiddenError /> : <InternalError />}
    </Box>
  );
};
