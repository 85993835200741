import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ResetPasswordValidationProps } from "../redux/types";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Layout } from "../../components/layouts/auth/layout";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ResetPasswordRequest } from "../redux/api";
import { toast } from "react-toastify";

export const ResetPassword = () => {
  const { user, error, status, authorized } = useAppSelector(
    (state) => state.auth
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const schema: yup.Schema<ResetPasswordValidationProps> = useMemo(() => {
    return yup.object({
      password: yup
        .string()
        .required(t("validations.field_is_required"))
        .min(6, t("validations.field_must_be_at_least_characters", { min: 6 })),
      password_confirmation: yup
        .string()
        .required(t("validations.field_is_required"))
        .oneOf([yup.ref("password")], t("validations.passwords_must_match")),
      code: yup
        .string()
        .required(t("validations.field_is_required"))
        .min(4, t("validations.field_must_be_at_least_characters", { min: 4 }))
        .max(
          4,
          t("validations.field_must_not_be_greater_than_characters", { max: 4 })
        ),
    });
  }, [t]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ResetPasswordValidationProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      password_confirmation: "",
      code: "",
    },
  });

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  useEffect(() => {
    if (status === "failed" && error) {
      const errorCode = error.code as number;

      switch (errorCode) {
        case 1000:
          setError(
            "code",
            { message: t("reset_password.code_not_found"), type: "validate" },
            {
              shouldFocus: true,
            }
          );
          return;
        case 429:
          toast.warning(t("notifications.too_many_requests"));
          return;
      }
    }

    if (status === "success" && authorized) {
      toast.success(t("reset_password.success"));
      navigate("/?rehydrate=false");
    }
  }, [authorized, error, navigate, setError, status, t]);

  const onSubmit = (data: ResetPasswordValidationProps) => {
    if (!user) return;

    dispatch(
      ResetPasswordRequest({
        ...data,
        email: user.email,
      })
    );
  };

  return (
    <Layout title={"reset_password.title"}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        rowGap={2}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        width={"100%"}
      >
        <FormControl fullWidth error={!!errors.password}>
          <Controller
            render={({ field }) => (
              <TextField
                error={!!errors.password}
                type={"password"}
                variant={"outlined"}
                label={t("labels.password")}
                {...field}
                InputLabelProps={{ shrink: !!field.value }}
              />
            )}
            name={"password"}
            control={control}
          />
          <FormHelperText>{errors.password?.message}</FormHelperText>
        </FormControl>
        <FormControl fullWidth error={!!errors.password_confirmation}>
          <Controller
            render={({ field }) => (
              <TextField
                error={!!errors.password_confirmation}
                type={"password"}
                variant={"outlined"}
                label={t("labels.password_confirmation")}
                {...field}
                InputLabelProps={{ shrink: !!field.value }}
              />
            )}
            name={"password_confirmation"}
            control={control}
          />
          <FormHelperText>
            {errors.password_confirmation?.message}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth error={!!errors.code}>
          <Controller
            render={({ field }) => (
              <TextField
                error={!!errors.code}
                type={"number"}
                variant={"outlined"}
                label={t("labels.code")}
                {...field}
                InputLabelProps={{ shrink: !!field.value }}
              />
            )}
            name={"code"}
            control={control}
          />
          <FormHelperText>{errors.code?.message}</FormHelperText>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t("reset_password.reset")}
        </Button>
      </Box>
    </Layout>
  );
};
