import { useTranslation } from "react-i18next";
import { AnswerInterface } from "../redux/types";
import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { AnswerDetails } from "./show";
import { useNavigate } from "react-router-dom";

type AnswerListProps = {
  answers: AnswerInterface[];
};

export const AnswerList = (props: AnswerListProps) => {
  const { t } = useTranslation();
  const { answers } = props;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box
        marginTop={8}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={700} variant="h5">
          {t("answers.title")}
        </Typography>
        <Button
          variant={"contained"}
          onClick={() => navigate("answers/create")}
          color={"primary"}
        >
          {t("answers.create")}
        </Button>
      </Box>

      <Grid spacing={2} container marginTop={2}>
        {answers.map((answer) => (
          <Grid key={answer.id} item lg={4} md={6} sm={12}>
            <AnswerDetails answer={answer} />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};
