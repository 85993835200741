import { DataTypeEnum, SuccessResponseInterface } from "app/types";

export interface SettingInterface {
  id: number;
  key: SettingEnum;
  value: any;
  data_type: DataTypeEnum;
}

export interface GetSettingsSuccessResponse extends SuccessResponseInterface {
  data: SettingInterface[];
}

export interface GetSettingSuccessResponse extends SuccessResponseInterface {
  data: SettingInterface;
}

export enum SettingEnum {
  SCORE_FOR_EASY_QUESTION = "score_for_easy_question",
  SCORE_FOR_MEDIUM_QUESTION = "score_for_medium_question",
  SCORE_FOR_HARD_QUESTION = "score_for_hard_question",
  QUESTIONS_FOR_LEVEL_COUNT = "questions_for_level_count",
  PRICE_FOR_HINT_ON_EASY_QUESTION = "price_for_hint_on_easy_question",
  PRICE_FOR_HINT_ON_MEDIUM_QUESTION = "price_for_hint_on_medium_question",
  PRICE_FOR_HINT_ON_HARD_QUESTION = "price_for_hint_on_hard_question",
  FIRST_TIME_BONUS_TIME = "first_time_bonus_time",
  FIRST_TIME_BONUS_POINTS = "first_time_bonus_points",
  SECOND_TIME_BONUS_TIME = "second_time_bonus_time",
  SECOND_TIME_BONUS_POINTS = "second_time_bonus_points",
  THIRD_TIME_BONUS_TIME = "third_time_bonus_time",
  THIRD_TIME_BONUS_POINTS = "third_time_bonus_points",
  TIME_FOR_GAME = "time_for_game",
  PRICE_POOL = "price_pool",
}

export interface SaveSettingPropsInterface {
  id: number;
  value: any;
  key: SettingEnum;
  data_type: DataTypeEnum;
}
