import { Box } from "@mui/material";
import { AwardCreate } from "./create";
import { AwardEdit } from "./edit";
import { AwardList } from "./index";
import { AwardHistory } from "./history";

type Props = {
  children: JSX.Element;
};

export const Award = ({ children }: Props) => {
  return (
    <Box flex={1} flexDirection={"column"}>
      {children}
    </Box>
  );
};

Award.Create = AwardCreate;
Award.Edit = AwardEdit;
Award.List = AwardList;
Award.History = AwardHistory;
