import {
  AnswerInterface,
  DeleteAnswerPropsInterface,
  GetAnswerPropsInterface,
  GetAnswerSuccessResponse,
  SaveAnswerPropsInterface,
} from "./types";
import { quizApi } from "./../../../app/quizApi";

export const answerApi = quizApi.injectEndpoints({
  endpoints: (build) => ({
    getAnswer: build.query<AnswerInterface, GetAnswerPropsInterface>({
      query: ({ id, question_id }) =>
        `/api/panel/questions/${question_id}/answers/${id}`,
      providesTags: (result, error, { id }) => [{ type: "Answers", id: id }],
      transformResponse: (response: GetAnswerSuccessResponse) => response.data,
    }),
    storeAnswer: build.mutation<void, SaveAnswerPropsInterface>({
      query: ({ question_id, ...body }) => ({
        url: `/api/panel/questions/${question_id}/answers`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { question_id }) => [
        { type: "Questions", id: question_id },
      ],
    }),
    deleteAnswer: build.mutation<void, DeleteAnswerPropsInterface>({
      query: ({ id, question_id }) => ({
        url: `/api/panel/questions/${question_id}/answers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { question_id }) => [
        { type: "Questions", id: question_id },
      ],
    }),
    updateAnswer: build.mutation<
      void,
      SaveAnswerPropsInterface & { id: number | string }
    >({
      query: ({ id, question_id, ...body }) => ({
        url: `/api/panel/questions/${question_id}/answers/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { question_id }) => [
        { type: "Questions", id: question_id },
      ],
    }),
  }),
});

export const {
  useDeleteAnswerMutation,
  useUpdateAnswerMutation,
  useGetAnswerQuery,
  useStoreAnswerMutation,
} = answerApi;
