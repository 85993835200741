import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import pl from "./pl.json";
import intervalPlural from "i18next-intervalplural-postprocessor";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

export const supportedLanguages = ["pl"];

i18n
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    detection: options,
    returnNull: false,
    debug: true,
    fallbackLng: "pl",
    supportedLngs: supportedLanguages,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      pl: {
        translation: pl,
      },
    },
  });

export default i18n;
