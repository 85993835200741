import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { UpdatePasswordPropsInterface } from "../redux/types";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { UpdatePasswordRequest } from "../redux/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const AuthSetting = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const schema: yup.Schema<UpdatePasswordPropsInterface> = useMemo(() => {
    return yup.object({
      password: yup.string().required(t("validations.field_is_required")),
      new_password: yup
        .string()
        .required(t("validations.field_is_required"))
        .min(
          6,
          `${t("validations.field_must_be_at_least_characters", {
            min: 6,
          })} ${t("characters_interval", {
            postProcess: "interval",
            count: 6,
          })}`
        ),
      new_password_confirmation: yup
        .string()
        .required(t("validations.field_is_required"))
        .oneOf(
          [yup.ref("new_password"), ""],
          t("validations.passwords_must_match")
        ),
    });
  }, [t]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdatePasswordPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      new_password: "",
      new_password_confirmation: "",
    },
  });

  const onSubmit = (data: UpdatePasswordPropsInterface) => {
    dispatch(UpdatePasswordRequest(data));
  };

  useEffect(() => {
    if (status === "success") {
      toast.success(t("notifications.password_updated"));
      navigate("/");
    }
  }, [navigate, status, t]);

  return (
    <Box flex={1}>
      <Typography marginBottom={4} fontWeight={700} variant="h5">
        {t("profile_settings")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  {t("change_password")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Box display={"flex"} rowGap={3} flexDirection={"column"}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size={"small"}
                        fullWidth
                        {...field}
                        label={t("labels.current_password")}
                        type="password"
                        variant="outlined"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                      />
                    )}
                  />
                  <Controller
                    name="new_password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size={"small"}
                        fullWidth
                        {...field}
                        label={t("labels.new_password")}
                        type="password"
                        error={!!errors.new_password}
                        helperText={errors.new_password?.message}
                      />
                    )}
                  />
                  <Controller
                    name="new_password_confirmation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size={"small"}
                        fullWidth
                        {...field}
                        label={t("labels.confirm_new_password")}
                        type="password"
                        variant="outlined"
                        error={!!errors.new_password_confirmation}
                        helperText={errors.new_password_confirmation?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box marginTop={4}>
          <Button type="submit" variant="contained" color="primary">
            {t("save")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
