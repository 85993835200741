import { Skeleton, Theme, useMediaQuery } from "@mui/material";
import React from "react";

export const TableSkeleton = () => {
  return (
    <React.Fragment>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={50}
        style={{ marginBottom: 10 }}
      />
    </React.Fragment>
  );
};
