import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetUserQuery, useUpdateUserMutation } from "../api/repository";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { SaveUserPropsInterface } from "../api/types";
import { UserForm } from "./parts/form";
import { DetailsSkeleton } from "../../components/skeletons/details";
import { Box } from "@mui/material";

export const UserEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [updateUser, { error, isError, isSuccess, isLoading }] =
    useUpdateUserMutation();
  const userQuery = useGetUserQuery(id!);
  const navigate = useNavigate();

  const handleSubmit = (data: SaveUserPropsInterface) => {
    updateUser({ id: id!, ...data });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("users.updated"));
      navigate("/users");
    }
  }, [isSuccess, navigate, t]);

  return (
    <Box flex={1}>
      {isLoading || userQuery.isLoading ? (
        <DetailsSkeleton />
      ) : (
        <UserForm
          onSubmit={handleSubmit}
          error={error}
          isError={isError}
          title={"users.edit"}
          data={userQuery.data}
        />
      )}
    </Box>
  );
};
