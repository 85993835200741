export interface SuccessResponseInterface {
  data: any;
  code: number;
}

export interface PaginationDataInterface<T> extends SuccessResponseInterface {
  data: {
    data: T[];
    pagination: PaginationInterface;
  };
}

export interface PaginationInterface {
  current_page: number;
  total: number;
  total_pages: number;
  count: number;
}

export interface HasIconInterface {
  icon_path?: string;
}

export enum SocialProvider {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
}

export enum ResponseSuccessCode {
  OK = 200,
}

export interface PaginationPropsInterface {
  page: number | undefined;
  per_page: number | undefined;
}

export enum TimeFormatEnum {
  MINUTES = "minute",
  DAYS = "day",
  HOURS = "hour",
}

export type TranslationProp = {
  [key: string]: string;
};

export type FormProps<SaveInterface, DataInterface> = {
  onSubmit: (data: SaveInterface) => void;
  error: any;
  isError: boolean;
  data?: DataInterface;
  title: string;
};

export enum DataTypeEnum {
  BOOL = "bool",
  STRING = "string",
  DATE = "date",
  FLOAT = "float",
  INTEGER = "integer",
}

export interface ErrorResponseInterface {
  data: any;
  code: number;
}
