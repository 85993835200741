import { Skeleton, Theme, useMediaQuery } from "@mui/material";
import React from "react";

export const DetailsSkeleton = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Skeleton
        variant="rounded"
        animation="wave"
        width={matches ? "100%" : 400}
        height={50}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rounded"
        animation="wave"
        width={"100%"}
        height={300}
      />
    </React.Fragment>
  );
};
