import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AnswerForm } from "./parts/form";
import { useStoreAnswerMutation } from "../redux/api";
import { FormAnswerProps } from "../redux/types";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const AnswerCreate = () => {
  const { question_id } = useParams<{ question_id: string }>();
  const { t } = useTranslation();
  const [storeAnswer, { isSuccess, isError, error }] = useStoreAnswerMutation();

  const handleSubmit = (data: FormAnswerProps) => {
    storeAnswer({
      ...data,
      question_id: question_id!,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("notifications.answer_created"));
    }
  }, [isSuccess, t]);

  return (
    <Box flex={1}>
      <AnswerForm
        title="create_answer"
        error={error}
        isError={isError}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};
