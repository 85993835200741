import { quizApi } from "app/quizApi";
import {
  GetSettingsSuccessResponse,
  GetSettingSuccessResponse,
  SaveSettingPropsInterface,
  SettingInterface,
} from "./types";

export const settingRepository = quizApi.injectEndpoints({
  endpoints: (build) => ({
    getSettings: build.query<SettingInterface[], void>({
      query: () => "/api/v1/settings",
      transformResponse: (response: GetSettingsSuccessResponse) =>
        response.data,
      providesTags: (result) => {
        if (result) {
          return result.map((item) => ({ type: "Settings", id: item.id }));
        } else {
          return [{ type: "Settings", id: "LIST" }];
        }
      },
    }),
    getSetting: build.query<SettingInterface, number | string>({
      query: (id) => `/api/panel/settings/${id}`,
      transformResponse: (response: GetSettingSuccessResponse) => response.data,
      providesTags: (result, error, id) => [{ type: "Settings", id }],
    }),
    updateSetting: build.mutation<void, SaveSettingPropsInterface>({
      query: ({ id, ...data }) => ({
        url: `/api/panel/settings/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Settings", id },
        { type: "Settings", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useGetSettingQuery,
  useUpdateSettingMutation,
} = settingRepository;
