import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import "./languages/i18n";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

const container = document.getElementById("root")!;
const root = createRoot(container);

const boxShadowTheme =
  "rgb(0 0 0 / 4%) 0px 5px 22px, rgb(0 0 0 / 3%) 0px 0px 0px 0.5px;";

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#1C2536",
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Helvetica",
      "Arial",

      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
    ].join(","),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...{
            boxShadow: boxShadowTheme,
          },
        }),
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...{
            boxShadow: boxShadowTheme,
          },
        }),
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: ({ theme, ownerState }) => ({
          ...{
            backgroundColor: theme.palette.primary.main,
            color: "white",
            ":hover": { backgroundColor: theme.palette.primary.dark },
          },
        }),
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CssVarsProvider theme={theme}>
          <ToastContainer hideProgressBar={true} theme="colored" />
          <CssBaseline />
          <RouterProvider router={router} />
        </CssVarsProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
