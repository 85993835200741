import { quizApi } from "app/quizApi";
import {
  GetUsersSuccessResponse,
  GetUserSuccessResponse,
  SaveUserPropsInterface,
  UserInterface,
} from "./types";
import { PaginationPropsInterface } from "../../../app/types";

export const userRepository = quizApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<
      GetUsersSuccessResponse["data"],
      PaginationPropsInterface
    >({
      query: (pagination) => ({
        url: "/api/panel/users",
        params: pagination,
      }),
      transformResponse: (response: GetUsersSuccessResponse) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Users", id } as const)),
              { type: "Users", id: "LIST" },
            ]
          : [{ type: "Users", id: "LIST" }],
    }),
    storeUser: builder.mutation<void, SaveUserPropsInterface>({
      query: (data) => ({
        url: "/api/panel/users",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
    updateUser: builder.mutation<
      void,
      SaveUserPropsInterface & { id: string | number }
    >({
      query: (data) => ({
        url: `/api/panel/users/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Users", id: "LIST" },
        { type: "Users", id },
      ],
    }),
    getUser: builder.query<UserInterface, number | string>({
      query: (id) => `/api/panel/users/${id}`,
      transformResponse: (response: GetUserSuccessResponse) => response.data,
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useStoreUserMutation,
  useUpdateUserMutation,
  useGetUserQuery,
} = userRepository;
