import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetSettingQuery,
  useUpdateSettingMutation,
} from "../api/repository";
import { DataTypeEnum } from "../../../app/types";
import { toast } from "react-toastify";
import { DetailsSkeleton } from "../../components/skeletons/details";

export const SettingEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const settingQuery = useGetSettingQuery(id!);
  const [value, setValue] = useState<string | number>();
  const [error, setError] = useState<string>();
  const [updateSetting, { isLoading, isSuccess }] = useUpdateSettingMutation();
  const { data, isLoading: isSettingLoading } = useGetSettingQuery(id!);

  console.log(data);

  const handleSubmit = () => {
    if (!value) {
      setError(t("validations.field_is_required"));
      return;
    }

    const castedValue = castValue(value, settingQuery.data?.data_type);

    if (!settingQuery.data) return;

    updateSetting({
      id: settingQuery.data.id,
      value: castedValue,
      data_type: settingQuery.data.data_type,
      key: settingQuery.data.key,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("settings.updated"));
    }
  }, [isSuccess, t]);

  useEffect(() => {
    if (data) {
      setValue(data.value);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Box flex={1}>
        <Typography marginBottom={4} variant="h5" fontWeight={700} gutterBottom>
          {t("settings.edit")}
        </Typography>
        {isSettingLoading ? (
          <DetailsSkeleton />
        ) : (
          <React.Fragment>
            <Box rowGap={4} display={"flex"} flexDirection={"column"}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={700}
                        gutterBottom
                      >
                        {t("settings.value")}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        gutterBottom
                      >
                        {settingQuery.data &&
                          t(`settings.keys.${settingQuery.data.key}`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          type={resolveInputType(settingQuery.data?.data_type)}
                          size={"small"}
                          label={t("labels.value")}
                          sx={{ marginTop: "5px" }}
                          InputLabelProps={{ shrink: !!value }}
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        />
                        <FormHelperText>
                          {error && (
                            <Typography variant={"subtitle2"}>
                              {error}
                            </Typography>
                          )}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            <Box marginTop={4}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                {t("save")}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};

const resolveInputType = (dataType?: DataTypeEnum) => {
  switch (dataType) {
    case DataTypeEnum.INTEGER:
    case DataTypeEnum.FLOAT:
      return "number";
    case DataTypeEnum.DATE:
      return "date";
    default:
      return "text";
  }
};

const castValue = (value: string | number, dataType?: DataTypeEnum) => {
  switch (dataType) {
    case DataTypeEnum.INTEGER:
      return parseInt(value as string);
    case DataTypeEnum.FLOAT:
      return parseFloat(value as string);
    default:
      return value;
  }
};
