import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { QuestionForm } from "./parts/form";
import { useStoreQuestionMutation } from "../redux/api";
import { SaveQuestionPropsInterface } from "../redux/types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

export const QuestionCreate = () => {
  const { t } = useTranslation();
  const [storeQuestion, { isSuccess, error, isError }] =
    useStoreQuestionMutation();
  const navigate = useNavigate();

  const onSubmit = (data: SaveQuestionPropsInterface) => {
    storeQuestion(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("notifications.question_created"));
      navigate("/questions");
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <Box flex={1}>
        <QuestionForm
          onSubmit={onSubmit}
          title="create_question"
          error={error}
          isError={isError}
        />
      </Box>
    </React.Fragment>
  );
};
