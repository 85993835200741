import { store } from "./../../../app/store";
import {
  ForgotPasswordPropsInterface,
  LogInPropsInterface,
  LogInSuccessResponseInterface,
  ResetPasswordPropsInterface,
  UpdatePasswordPropsInterface,
} from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { ErrorResponseInterface } from "../../../app/types";

const url = process.env.REACT_APP_BACKEND_URL;

export const LoginRequest = createAsyncThunk<
  LogInSuccessResponseInterface,
  LogInPropsInterface
>("auth/login", async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/api/v1/auth/login`, data);

    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      thunkAPI.rejectWithValue(err.response?.data);
    }

    return thunkAPI.rejectWithValue(err);
  }
});

export const UpdatePasswordRequest = createAsyncThunk<
  void,
  UpdatePasswordPropsInterface
>("auth/update-password", async (data, thunkAPI) => {
  const response = await fetch(`${url}/api/v1/auth/update-password`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${store.getState().auth.bearerToken}`,
    },
    body: JSON.stringify(data),
  });

  return await response.json();
});

export const ForgotPasswordRequest = createAsyncThunk<
  void,
  ForgotPasswordPropsInterface,
  { rejectValue: ErrorResponseInterface }
>("auth/forgot-password", async (data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/api/v1/auth/forgot-password`,
      data
    );

    return response.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      return thunkAPI.rejectWithValue({
        code: err.response.status,
        data: err.response.data,
      });
    }

    return thunkAPI.rejectWithValue({
      code: 500,
      data: null,
    });
  }
});

export const ResetPasswordRequest = createAsyncThunk<
  LogInSuccessResponseInterface,
  ResetPasswordPropsInterface,
  { rejectValue: ErrorResponseInterface }
>("auth/reset-password", async (data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/api/v1/auth/reset-password`,
      data
    );

    return response.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      return thunkAPI.rejectWithValue({
        code: err.response.status,
        data: err.response.data,
      });
    }

    return thunkAPI.rejectWithValue({
      code: 500,
      data: null,
    });
  }
});

export const LogoutRequest = createAsyncThunk<void, void>(
  "auth/logout",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/api/v1/auth/logout`, [], {
        headers: {
          Authorization: `Bearer ${store.getState().auth.bearerToken}`,
        },
      });

      return response.data;
    } catch (err) {
      if (err instanceof AxiosError && err.response) {
        return thunkAPI.rejectWithValue({
          code: err.response.status,
          data: err.response.data,
        });
      }

      return thunkAPI.rejectWithValue({
        code: 500,
        data: null,
      });
    }
  }
);
